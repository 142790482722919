<template>
  <v-avatar
    class="mr-4"
    width="44px"
    @click="goToAuthorDetailsRoute()">
    <v-img
      v-if="author"
      :src="thumbnailUrl" />
  </v-avatar>
</template>

<script>
  import AuthorThumbnailMixin from '@/components/courses/course-details/author-thumbnail/AuthorThumbnail.mixin.vue';
  export default {
    mixins: [AuthorThumbnailMixin],
  };
</script>
