<script>
  import getThumbnail from '@/utils/getThumbnail.js';
  import { mapGetters } from 'vuex';

  export default {
    name: 'author-thumbnail',
    data() {
      return {
        author: null,
        thumbnailUrl: null,
      };
    },
    props: {
      uid: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters('AuthorsModule', ['getAuthor']),
    },
    async created() {
      this.author = this.getAuthor(this.uid);
      await this.setThumbnailUrl();
    },
    methods: {
      async setThumbnailUrl() {
        try {
          this.thumbnailUrl = await getThumbnail({
            url: this.author.photoURL,
            folder: 'AuthorsPhotos',
          });
        } catch {
          // fallback to the original image
          this.thumbnailUrl = this.author.photoURL;
        }
      },
      goToAuthorDetailsRoute() {
        this.$router.push({
          name: 'AuthorDetails',
          params: {
            authorId: this.uid,
          },
        });
      },
    },
  };
</script>
